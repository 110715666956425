$debug: false;
$graphicsPath: /magazine/dest/graphics;

/* Modular Scale */
:root {
	--ms-bases: 1rem; /* 16px */
	--ms-ratios: 1.125;
}

/* Core variables */

$white				:	#fff;
$black				:	#000;
$blackTransparent 	:   rgba(0, 0, 0, 0.6);
$blackTransparent1 	:   rgba(0, 0, 0, 0.76);
$blackTransparent025:   rgba(0, 0, 0, 0.25);
$blackTransparent035:   rgba(0, 0, 0, 0.35);
$blackTransparent040:   rgba(0, 0, 0, 0.40);
$blackTransparent03 :   rgba(0, 0, 0, 0.3);
$blackTransparent08 :   rgba(0,0,0,0.8);
$blackTransparent0  :   rgba(0,0,0,0);
$whiteTransparent025:   rgba(255, 255, 255, 0.25);
$whiteTransparent03 :   rgba(255, 255, 255, 0.3);
$whiteTransparent04 :   rgba(255, 255, 255, 0.4);
$whiteTransparent05 :   rgba(255, 255, 255, 0.5);
$whiteTransparent07 :   rgba(255, 255, 255, 0.7);
$whiteTransparent08	:	rgba(255, 255, 255, 0.8);
$grey1Transparent05	:	rgba(32, 32, 32, 0.5);
$grey1Transparent04	:	rgba(32, 32, 32, 0.4);
$grey1Transparent03	:	rgba(32, 32, 32, 0.24);

$grey0				:	#F7F8F8;
$grey1				:	#222;
$grey2				:	#444;
$grey3				:	#888;
$grey4				:	#DCDCDC;
$grey5				:	#F0F2F2;
$grey6				:	#E2E6E6;
$grey7				:	#313131;
$grey8				:	#FAFAFA;
$grey9              :    #F8F8F8;
$greyTransparent	:	rgba(34, 34, 34, 0.8);
$grey5Transparent05	:	rgba(240,242,242,0.5);
$grey5Transparent0	:	rgba(240,242,242,0);


$brandcolour1		:	#3EAFFA;
$brandcolour1t		:	#CFEBFE;
$brandcolour2		:	#006CB8;
$brandcolour2Drk	:	#004576;
$brandcolour3		:	#006CB8;

$brandcolour1transparent:	rgba(62, 175, 250, 0);
$brandcolour2transparent:	rgba(0, 108, 184, 0);
$brandcolour3transparent:	rgba(0, 108, 184, 0);

$boxborder			:	#FDC051;
$boxbg				:	#FFFADD;
$storytypemediabg	:	#595959;
$tabbgcolour		:	#DFDFDF;
$companylogoshadowcolour: rgba(0,0,0,0.05);
$companyheaderbgcolour: rgba(240,242,242,0.8);
$errorred			:	#D90000;
$hideselection		: 	#F7675A;
$highlight			:	#B2D7FF;
$horisontallineborder:	#ccc;
 	

$transparent		:	transparent;
$transparentwhite	:	rgba(255,255,255,0);

/* Directories */
$widgetControlsBorderColour: #999;
$widgetControlDisabledBorderColour: #C5C6C6;
$widgetControlDisabledBackgroundColour: #F8F9F9;
$widgetControlDisabledTextColour: #BBBCBC;
$srpbggradientstartcolour:  rgba(255,255,255,1);
$srpbggradientendcolour:  rgba(240,242,242,0.8);
$frmcontrolsshadowclour1:   rgba(0,0,0,.075);
$frmcontrolsshadowclour2:   rgba(102,175,233,.6);
$grey3t				:	#999;
$logowallshadowcolour:  rgba(0,0,0,0.1);
$logowallhoverbgcolour:  rgba(0,0,0,.2);
$companylogoshadowcolour: rgba(0,0,0,0.1);
$searchpagetabsshadowcolour: #E5E5E5;
$contactmapbordercolour: #b0b0b0;
$popuperrorbordercolour: #B3263C;
$popuperrorbgcolour: #F7E7EA;
$topiclistheadlinecolour: #202020;

/* Element variables (GLOBAL) */
$headingColor 		: 	#212121;
$mainMenuColor		:	#36454F;
$headingFontFamily 	: 	'PT Sans', sans-serif;
$baseFontFamily 	: 	'PT Sans', sans-serif;
$baselineGrid 		: 	0.25rem;