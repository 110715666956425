@import "_variables.css";
@import "_mixins/all.css";

body {
	background-color: $grey5;
}
.notSupported {
	display: block;
	padding: 20px;
	font-family: 'PT Sans', sans-serif;
	background-color: $white;
	text-align: center;
	.sleeve {
		max-width: 680px;
		margin: auto;
	}
	#logo {
		margin-bottom: 12px;
	}
	p:not(:last-of-type) {
		margin-bottom: 30px;
	}
	.button {
		text-decoration: none;
		padding: 10px;
	}
}